<!-- System: STA
    Purpose: This compoment will create video
            of videos from screenshots
-->
<template>
  <!-- Video Loader -->
  <v-dialog
    v-model="show_dialog"
    width="700"
    centered
    hide-footer
    hide-header
    id="create_video_modal"
    ref="create_video_modal"
    size="xl"
    title="Play Video"
  >
    <v-card class="rows m-0">
      <div class="col-12 col-sm-12 p-0">
        <!-- Loader  -->
        <Loader v-show="loader" />
        <!-- Video Player -->
        <div class="player">
          <video-player
            :options="playerOptions"
            :playsinline="true"
            @ready="playerReadied"
            class="vjs-custom-skin"
            ref="videoPlayer"
            v-show="video"
          >
          </video-player>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { baseURL } from "@/assets/js/urls";
import "@/assets/css/video-player.css";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  name: "CreateVideoModal",
  components: { videoPlayer, Loader: () => import("../includes/Loader") },

  props: ["video_data"],
  data() {
    return {
      show_dialog: false,
      video: "",
      loader: true,
      creating: false,
      playerOptions: {
        height: "360",
        muted: true,
        language: "en",
        playbackRates: [0.1, 0.3, 0.7, 1.0, 1.5],
      },
    };
  },
  mounted() {
    this.$root.$on("open_video_modal", () => {
      // this.createVideoCall();
      this.show_dialog = true;
    });
    this.$root.$on("reset_video", () => {
      this.video = "";
    });
  },
  watch: {
    show_dialog: {
      handler: function (new_val, old_val) {
        if (new_val && this.video == "") {
          this.createVideoCall();
        }
      },
    },
    video_data: {
      handler: function (new_val, old_val) {
        if (JSON.stringify(new_val) !== JSON.stringify(old_val))
          this.video = "";
      },
      deep: true,
    },
    video: function (val) {
      if (val != "") {
        this.playerOptions = {
          height: "360",
          muted: true,
          language: "en",
          playbackRates: [0.1, 0.3, 0.7, 1.0, 1.5],
          sources: [
            {
              type: "video/mp4",
              src: this.url() + "storage/" + this.video.file_path,
            },
          ],
        };
        this.player.load();
        this.player.playbackRate(0.1);
      }
    },
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    createVideoCall: _.debounce(function () {
      this.$store.commit("custom/toggle_loader", true);
      this.loader = true;
      this.creating = true;
      this.$store
        .dispatch("custom/get_work_diary_video", this.video_data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          this.creating = false;
          if (response.data.error === false) {
            let video_path = {
              file_path: response.data.file_path,
            };
            this.loader = false;
            this.video = video_path;
          } else {
            this.show_dialog = false;
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "red",
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", true);
          this.creating = false;
          this.show_dialog = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: "There was an error creating video please try again",
            snackbarColor: "red",
          });
        });
    }, 500),
    url() {
      return baseURL.API_URL;
    },
    playerReadied(player) {
      player.playbackRate(0.1);
    },
    onShown() {
      if (!this.video && !this.creating) {
        this.createVideo();
      }
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0 !important;
}
</style>
